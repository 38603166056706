import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonLinkType } from '../Button';
import Text from '../Text';
import TextCarousel from '../TextCarousel';
import './style.scss';

type Props = {
  carouselItems: string[];
};

const HomePageHeader = ({ carouselItems }: Props) => {
  const { t } = useTranslation();

  const maximumFontSize = useMemo(() => {
    const longestItemLength = Math.max(
      10,
      ...carouselItems.map((item) => item.replace(/\s/g, '').length)
    );
    const fontSize = (100 / longestItemLength).toFixed(2);
    return fontSize;
  }, [carouselItems]);

  return (
    <header>
      <div className="header-home">
        <div>
          <Text
            type="p"
            color="light"
            className="header-home__title header-home__title-top"
            style={{ '--font-size': maximumFontSize } as React.CSSProperties}
          >
            {t('homepage_heading_top')}
          </Text>
          <TextCarousel
            texts={carouselItems}
            textClassName="header-home__title"
            textStyle={
              { '--font-size': maximumFontSize } as React.CSSProperties
            }
          />

          <Text
            type="p"
            color="light"
            className="header-home__title"
            style={{ '--font-size': maximumFontSize } as React.CSSProperties}
          >
            {t('homepage_heading_bottom')}
          </Text>
        </div>
        <div className="header-home__info">
          <Button
            size="big"
            rounded
            color="secondary"
            className="header-home__info__button"
            href={'#message-us'}
            linkType={ButtonLinkType.ANCHOR}
          >
            <Text type="label" className="header-home__info__button__text">
              {t('homepage_heading_contact')}
            </Text>
          </Button>

          <div className="header-home__info__content">
            <div className="header-home__info__subheading">
              <Text
                type="p"
                color="light"
                className="header-home__info__subheading__text"
              >
                {t('homepage_subheading_start')}
              </Text>
              <Text
                type="h1"
                color="light"
                className="header-home__info__subheading__text"
              >
                {t('homepage_subheading_title')}
              </Text>
              <Text
                type="p"
                color="light"
                className="header-home__info__subheading__text"
              >
                {t('homepage_subheading_end')}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default memo(HomePageHeader);
