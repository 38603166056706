import React, { FC, memo, useCallback, useMemo } from 'react';
import './style.scss';
import Slider, { Settings } from 'react-slick';
import Text from '../Text';
import Category from '../Category';
import Button from '../Button';
import useScreenMode from '../../hooks/useScreenMode';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import useSlider from '../../hooks/useSlider';
import SliderHeader from '../SliderHeader';

export interface SliderData {
  title: string;
  subtitle: string;
  description: string;
  tags: string[];
  image: IGatsbyImageData;
  slug: string;
}

interface Props {
  title: string;
  projects: SliderData[];
}

enum SliderCenterPadding {
  Desktop = '10%',
  Mobile = '6%',
}

const LatestProjects: FC<Props> = ({ title, projects }) => {
  const screenMode = useScreenMode();

  const { sliderRef, onChangeSliderIndex } = useSlider(
    true,
    projects.length - 1
  );

  const settings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      slidesToShow: screenMode === 'desktop' ? 1.3 : 1,
      centerMode: screenMode === 'desktop',
      lazyLoad: 'ondemand',
      beforeChange: onChangeSliderIndex,
      infinite: false, //when infinite is true, react-slick creates cloned (duplicate) items which is not great in terms of seo
      centerPadding:
        screenMode === 'desktop'
          ? SliderCenterPadding.Desktop
          : SliderCenterPadding.Mobile,
    }),
    [onChangeSliderIndex, screenMode]
  );

  const renderSlides = useCallback(
    (item: SliderData, index: number) => (
      <div key={index} className="latest-projects__slider__slide">
        <div className="latest-projects__slider__slide__wrapper">
          <GatsbyImage
            image={item.image}
            alt="Latest project"
            objectFit="cover"
            className="latest-projects__slider__slide__image"
          />
          <div>
            <Text
              type="h3"
              weight="bold"
              className="latest-projects__slider__slide__title h2"
              color="light"
            >
              {item.title}
            </Text>
            <div className={'latest-projects__slider__slide__tags'}>
              {item.tags.map((tag, index) => (
                <Category isWide style="dark-grey" key={index}>
                  {tag}
                </Category>
              ))}
            </div>
          </div>
          <div>
            <Text
              type="p"
              weight="demi-bold"
              color="light"
              className="latest-projects__slider__slide__subtitle"
            >
              {item.subtitle}
            </Text>
            <Text
              type="p"
              color="light"
              className="latest-projects__slider__slide__description p-updated"
            >
              {item.description}
            </Text>
            <div className="latest-projects__slider__slide__buttons-container">
              <Button
                size="regular"
                href={`/product/${item.slug}/`}
                rounded
                color="dark-grey"
                className="latest-projects__slider__slide__button"
              >
                {'View case study'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    ),
    []
  );

  return (
    <section className="latest-projects">
      <div className="latest-projects__container">
        <SliderHeader
          title={title}
          color="light"
          subtitle={'SHUFFLE THROUGH THE SLIDER. DRAG OR SWIPE'}
        />
      </div>
      <Slider {...settings} ref={sliderRef}>
        {projects.map(renderSlides)}
      </Slider>
    </section>
  );
};

export default memo(LatestProjects);
