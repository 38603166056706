import React, { memo, useMemo } from 'react';
import './style.scss';
import ServiceTitle from '../ServiceTitle';
import { HomePageZoneGenius } from '../../utils/homePageTypes';
import { GatsbyImage } from 'gatsby-plugin-image';
import SliderControlButton from '../SliderControlButton';
import Slider, { Settings } from 'react-slick';
import useSlider from '../../hooks/useSlider';
import Text from '../Text';
import Link from '../Link';
import { useTranslation } from 'react-i18next';

type Props = {
  items: HomePageZoneGenius[];
};

const ZoneOfGenius: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();

  const {
    slideIndex,
    onSlickPrevious,
    onSlickNext,
    sliderRef,
    onChangeSliderIndex,
  } = useSlider();

  const settings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      lazyLoad: 'ondemand',
      draggable: false,
      infinite: false,
      beforeChange: onChangeSliderIndex,
    }),
    [onChangeSliderIndex]
  );

  const Items = useMemo(
    () =>
      items.map((item) => (
        <div key={item.title}>
          <GatsbyImage
            image={item.image.asset.gatsbyImageData}
            alt={item.title}
            objectFit="cover"
            className="zone-genius__image"
          />
        </div>
      )),
    [items]
  );

  const Links = useMemo(
    () =>
      items[slideIndex].links.map((link) => (
        <Link
          href={`/${link.slug.current}/`}
          key={link.title}
          className="zone-genius__info__links__item"
        >
          {link.title}
        </Link>
      )),
    [items, slideIndex]
  );

  return (
    <div className="zone-genius">
      <ServiceTitle color="light" className="zone-genius__title">
        {t('homepage_zone_genius')}
      </ServiceTitle>
      <Slider {...settings} ref={sliderRef}>
        {Items}
      </Slider>
      <div className="zone-genius__info">
        <Text type="h3" color="light" className="zone-genius__info__title">
          {items[slideIndex].title}
        </Text>
        <Text
          type="p"
          color="dark-grey"
          className="zone-genius__info__description"
        >
          {items[slideIndex].description}
        </Text>
        <div className="zone-genius__info__links">{Links}</div>
        <div className="zone-genius__info__arrows">
          <SliderControlButton
            color="light"
            direction="prev"
            onClick={onSlickPrevious}
            disabled={slideIndex === 0}
          />
          <SliderControlButton
            color="light"
            direction="next"
            onClick={onSlickNext}
            disabled={slideIndex === items.length - 1}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ZoneOfGenius);
