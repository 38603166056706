import React, { FC, memo } from 'react';

import './style.scss';
import { CompanyWithSlug } from '../../utils/homePageTypes';

const renderCompaniesIcons = (item: CompanyWithSlug, index: number) => (
  <a href={item.slug?.current}>
    <li
      dangerouslySetInnerHTML={{ __html: item.company.icon }}
      className="service__companies__list__item"
    ></li>
  </a>
);

interface Props {
  companies: CompanyWithSlug[];
}

const ServiceCompanies: FC<Props> = ({ companies }) => {
  return (
    <section className="service__companies service__container">
      <ul className="service__companies__list">
        {companies?.map(renderCompaniesIcons)}
      </ul>
    </section>
  );
};

export default memo(ServiceCompanies);
