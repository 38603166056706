import React, { memo, useMemo } from 'react';
import Text from '../Text';
import './style.scss';
import { HomePageSpecialization } from '../../utils/homePageTypes';
import ServiceTitle from '../ServiceTitle';

type Props = {
  title: string;
  items: HomePageSpecialization[];
};

const Specializations: React.FC<Props> = ({ title, items }) => {
  const Items = useMemo(
    () =>
      items.map((item, index) => (
        <div key={index} className="specializations__content__item">
          <Text type="h3" className="specializations__content__item__title">
            {item.title}
          </Text>
          <Text type="p" className="specializations__content__item__text">
            {item.description}
          </Text>
        </div>
      )),
    [items]
  );

  return (
    <div className="specializations">
      <ServiceTitle>{title}</ServiceTitle>
      <div className="specializations__content">{Items}</div>
    </div>
  );
};

export default memo(Specializations);
