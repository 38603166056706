import { graphql, PageProps } from 'gatsby';
import React, { useMemo } from 'react';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import SEO from '../components/seo';
import HomePageHeader from '../components/HomePageHeader';
import MessageUs from '../components/MessageUs';
import TehnologyUpdated from '../components/Tehnology/TehnologyUpdated';
import BlogPostsList from '../components/BlogPostsList';
import {
  ServiceAppsPreview,
  ServiceArticlesPreview,
} from '../utils/serviceTypes';
import CommentsUpdated from '../components/Comments/CommentsUpdated';
import AboutUs from '../components/AboutUs';
import Specializations from '../components/Specializations';
import LatestProjects, { SliderData } from '../components/LatestProjects';
import Services from '../components/Services';
import WorkProcess from '../components/WorkProcess';
import ZoneOfGenius from '../components/ZoneOfGenius';
import { HomePage } from '../utils/homePageTypes';
import ServiceCompanies from '../components/ServiceCompanies';

const IndexPage = ({
  data,
}: PageProps<{
  allSanityArticle: ServiceArticlesPreview;
  allSanityApp: ServiceAppsPreview;
  sanityHomePage: HomePage;
}>) => {
  const {
    allSanityArticle,
    allSanityApp,
    sanityHomePage: {
      companiesWithSlugs,
      specializationsTitle,
      specializations,
      services,
      projects,
      workflow,
      zoneGenius,
      feedback,
      blog,
      technologies,
      homepageCarouselItems,
    },
  } = data;

  const apps = useMemo<SliderData[]>(
    () =>
      allSanityApp.nodes
        .filter((item) => projects.items.includes(item.title))
        .map((item) => ({
          title: item.title,
          subtitle: item.moto,
          description: item.serviceData.description,
          tags: item.tags,
          image: item.serviceData.image.asset.gatsbyImageData,
          slug: item.slug.current,
        })),
    [allSanityApp.nodes, projects.items]
  );

  return (
    <Layout>
      <Navigation headerBackgroundColor="none" />
      <HomePageHeader carouselItems={homepageCarouselItems} />
      <ServiceCompanies companies={companiesWithSlugs} />
      <ZoneOfGenius items={zoneGenius} />
      <Specializations title={specializationsTitle} items={specializations} />
      <LatestProjects title={projects.title} projects={apps} />
      <Services items={services} />
      <AboutUs />
      <CommentsUpdated title={feedback.title} commentsOrder={feedback.items} />
      <WorkProcess workflow={workflow} />
      <BlogPostsList
        articles={allSanityArticle}
        selectedArticles={blog.articles}
        mainArticleDescription={blog.mainArticleDescription}
        title={blog.title}
      />
      <TehnologyUpdated
        tabs={technologies.tabs}
        header={technologies.title}
        details={technologies.details}
      />
      <MessageUs />
      <Footer noFooterCard isLegacy={false} />
    </Layout>
  );
};

export const query = graphql`
  query Homepage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allSanityArticle {
      nodes {
        slug {
          current
        }
        tags
        title
        _updatedAt
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    allSanityApp(sort: { fields: order, order: ASC }) {
      nodes {
        title
        tags
        moto
        slug {
          current
        }
        serviceData {
          description
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    sanityHomePage {
      homepageCarouselItems
      companiesWithSlugs {
        company {
          icon
        }
        slug {
          current
        }
      }
      specializationsTitle
      specializations {
        title
        description
      }
      services {
        title
        description
        slug {
          current
        }
      }
      projects {
        title
        items
      }
      workflow {
        title
        items {
          title
          description
        }
        description
        buttonTitle
        buttonHref
      }
      zoneGenius {
        title
        description
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        links {
          title
          slug {
            current
          }
        }
      }
      feedback {
        title
        items
      }
      blog {
        articles {
          readTime
          slug
        }
        mainArticleDescription
        title
      }
      technologies {
        title
        tabs {
          title
          code
        }
        details {
          title
          items
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Mobile and Web Development Company | Stormotion"
    description="Hire Stormotion to build your next highly scalable custom web or mobile application with JavaScript. We’re recognized as a top mobile application development company and are experts in React, React Native, Node.js."
  />
);

export default React.memo(IndexPage);
