import React, { memo, useMemo } from 'react';
import Text from '../Text';
import './style.scss';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { HomePageService } from '../../utils/homePageTypes';
import ServiceTitle from '../ServiceTitle';

type Props = {
  items: HomePageService[];
};

const Services: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();

  const Items = useMemo(
    () =>
      items.map((item, index) => (
        <div key={index} className="services__item">
          <div>
            <Text type="h3" className="services__item__title">
              {item.title}
            </Text>
            <Text type="p" className="services__item__text">
              {item.description}
            </Text>
          </div>
          <Button
            size="big"
            rounded
            color="dark"
            href={item.slug?.current ? `/${item.slug.current}/` : undefined}
            className={
              item.slug?.current
                ? 'services__item__button'
                : 'services__item__button services__item__button--hidden'
            }
          >
            <Text
              type="label"
              color="light"
              className="services__item__button__text"
            >
              {t('homepage_services_readmore')}
            </Text>
          </Button>
        </div>
      )),
    [items, t]
  );

  return (
    <div className="services">
      <ServiceTitle className="services__title">
        {t('homepage_services')}
      </ServiceTitle>
      {Items}
    </div>
  );
};

export default memo(Services);
