import React, { memo } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import Text from '../Text';
import Button, { ButtonLinkType } from '../Button';

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us-home">
      <div className="about-us-home__wrapper">
        <div className="about-us-home__content-container">
          <div className="about-us-home__flag">
            <div className="about-us-home__flag__left" />
            <div className="about-us-home__flag__right" />
          </div>
          <Text type="p" color="light" className="about-us-home__title">
            {t('homepage_about_top')}
          </Text>
        </div>
        <div className="about-us-home__content-container about-us-home__content-container-bottom">
          <Text
            type="p"
            color="light"
            className="about-us-home__title  about-us-home__title-bottom"
          >
            {t('homepage_about_bottom')}
          </Text>
          <Button
            size="big"
            rounded
            color="secondary"
            className="about-us-home__button"
            href="/#message-us"
            linkType={ButtonLinkType.ANCHOR}
          >
            <Text type="label" className="about-us-home__button__text">
              {t('letsTalk')}
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(AboutUs);
