import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './style.scss';
import Text from '../Text';
import Button, { ButtonLinkType } from '../Button';
import SliderHeader from '../SliderHeader';
import SliderControlButton from '../SliderControlButton';
import { Service } from '../../utils/serviceTypes';
import { useIsDesktop } from '../../hooks/useIsDesktop';

interface SliderData {
  title: string;
  description: string;
}

const renderSLides = (item: SliderData, index: number) => (
  <li key={index} className="work-process__slider__slide">
    <Text
      type="h3"
      weight="medium"
      className="work-process__slider__slide__title"
    >
      {item.title}
    </Text>
    <p className="work-process__slider__slide__description p-updated">
      {item.description}
    </p>
  </li>
);

const SCROLL_STEP_WIDTH = 400;

interface Props {
  workflow: Service['workflow'];
}

const WorkProcess: FC<Props> = ({ workflow }) => {
  const sliderRef = useRef<HTMLUListElement>(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const isDesktop = useIsDesktop();

  const updateButtonsState = useCallback(() => {
    if (!sliderRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;

    setIsPrevDisabled(scrollLeft <= 0);
    setIsNextDisabled(scrollLeft + clientWidth >= scrollWidth);
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    updateButtonsState();

    slider.addEventListener('scroll', updateButtonsState);
    return () => slider.removeEventListener('scroll', updateButtonsState);
  }, [updateButtonsState]);

  const onScrollNext = useCallback(() => {
    const currentPosition = sliderRef.current?.scrollLeft ?? 0;

    sliderRef.current?.scrollTo({
      left: currentPosition + SCROLL_STEP_WIDTH,
      behavior: 'smooth',
    });
  }, []);

  const onScrollPrev = useCallback(() => {
    const currentPosition = sliderRef.current?.scrollLeft ?? 0;

    sliderRef.current?.scrollTo({
      left: currentPosition - SCROLL_STEP_WIDTH,
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className="work-process">
      <div className="work-process__header">
        <SliderHeader
          title={workflow.title}
          subtitle={
            isDesktop
              ? 'use arrows below to SHUFFLE THROUGH THE SLIDER'
              : 'SHUFFLE THROUGH THE SLIDER. DRAG OR SWIPE'
          }
        />
      </div>
      <ul className="work-process__slider" ref={sliderRef}>
        {workflow.items.map(renderSLides)}
      </ul>
      <div className="work-process__footer">
        <div>
          <Text type="p" className="work-process__description p-updated">
            {workflow.description}
          </Text>
          <Button
            size="regular"
            rounded
            outerLink
            href={workflow.buttonHref}
            color="secondary"
            className="work-process__button"
            linkType={ButtonLinkType.ANCHOR}
          >
            {workflow.buttonTitle}
          </Button>
        </div>
        <div className="work-process__slider__controls">
          <SliderControlButton
            onClick={onScrollPrev}
            direction="prev"
            disabled={isPrevDisabled}
          />
          <SliderControlButton
            onClick={onScrollNext}
            direction="next"
            disabled={isNextDisabled}
          />
        </div>
      </div>
    </section>
  );
};

export default memo(WorkProcess);
