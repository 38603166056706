import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useMemo,
  useCallback,
} from 'react';
import './style.scss';
import Text from '../Text';

interface TextCarouselProps {
  texts: string[];
  textClassName?: string;
  textStyle?: React.CSSProperties;
}

const TextCarousel: React.FC<TextCarouselProps> = ({
  texts,
  textClassName,
  textStyle,
}) => {
  const [currentIndex, setCurrentIndex] = useState(texts.length * 2);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const isTransitioning = useRef<boolean>(false);
  const startX = useRef(0);
  const deltaX = useRef(0);
  const isDragging = useRef(false);
  const [itemWidths, setItemWidths] = useState<number[]>([]);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const extendedTexts = useMemo(() => Array(5).fill(texts).flat(), [texts]);

  useEffect(() => {
    const checkFontsLoaded = async () => {
      await document.fonts.ready;
      setFontsLoaded(true);
    };

    checkFontsLoaded();
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(orientation: landscape)');
    const isPC = !/Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(
      navigator.userAgent
    );

    const handleResize = () => {
      setDisableAnimation(true);
      setCurrentIndex(texts.length * 2);

      setTimeout(() => {
        setDisableAnimation(false);
      }, 100);
    };

    const updateWidths = () => {
      if (carouselRef.current) {
        const items =
          carouselRef.current.querySelectorAll<HTMLDivElement>(
            '.carousel__item'
          );
        const widths = Array.from(items).map((item) => item.offsetWidth + 32);
        setItemWidths(widths);
      }
    };

    if (fontsLoaded) {
      updateWidths();
    }

    if (isPC) {
      window.addEventListener('resize', () => {
        handleResize();
        updateWidths();
      });
    }

    mediaQueryList.addEventListener('change', () => {
      handleResize();
      updateWidths();
    });

    return () => {
      window.removeEventListener('resize', () => {
        handleResize();
        updateWidths();
      });
      mediaQueryList.removeEventListener('change', () => {
        handleResize();
        updateWidths();
      });
    };
  }, [fontsLoaded, texts]);

  useEffect(() => {
    if (carouselRef.current) {
      const items =
        carouselRef.current.querySelectorAll<HTMLDivElement>('.carousel__item');
      const widths = Array.from(items).map((item) => item.offsetWidth + 32);
      setItemWidths(widths);
    }
  }, [texts]);

  useEffect(() => {
    if (itemWidths.length > 0) {
      setCurrentIndex(texts.length * 2);
    }
  }, [itemWidths]);

  useEffect(() => {
    const interval = setInterval(() => {
      prevSlide();
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex, itemWidths]);

  useEffect(() => {
    isTransitioning.current = false;
  }, [currentIndex]);

  const getTranslateX = useCallback(() => {
    if (!itemWidths.length) return '0px';
    const offset = itemWidths
      .slice(0, currentIndex)
      .reduce((acc, width) => acc + width, 0);
    return `-${offset}px`;
  }, [itemWidths, currentIndex]);

  const nextSlide = useCallback(() => {
    if (isTransitioning.current || !itemWidths.length) return;
    isTransitioning.current = true;
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [itemWidths]);

  const prevSlide = useCallback(() => {
    if (isTransitioning.current || !itemWidths.length) return;
    isTransitioning.current = true;
    setCurrentIndex((prevIndex) => prevIndex - 1);
  }, [itemWidths]);

  useEffect(() => {
    if (currentIndex === extendedTexts.length - texts.length) {
      setTimeout(() => {
        setDisableAnimation(true);
        isTransitioning.current = false;
        setCurrentIndex(texts.length * 2);
      }, 500);
    } else if (currentIndex === 0) {
      setTimeout(() => {
        setDisableAnimation(true);
        isTransitioning.current = false;
        setCurrentIndex(extendedTexts.length - texts.length * 2);
      }, 500);
    } else {
      setDisableAnimation(false);
    }
  }, [currentIndex]);

  const handleDragStart = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      startX.current = 'touches' in e ? e.touches[0].clientX : e.clientX;
      isDragging.current = true;
    },
    []
  );

  const handleDragMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (!isDragging.current) return;
      deltaX.current =
        ('touches' in e ? e.touches[0].clientX : e.clientX) - startX.current;
    },
    []
  );

  const handleDragEnd = useCallback(() => {
    isDragging.current = false;
    if (deltaX.current > 50) {
      prevSlide();
    } else if (deltaX.current < -50) {
      nextSlide();
    }
    deltaX.current = 0;
  }, [prevSlide, nextSlide]);

  return (
    <div
      className="carousel"
      ref={carouselRef}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div
        className="carousel__content"
        style={{
          transform: `translateX(${getTranslateX()})`,
          transition: isTransitioning.current
            ? 'transform 0.5s ease-in-out'
            : 'none',
        }}
      >
        {extendedTexts.map((text, index) => {
          return (
            <Text
              type="p"
              key={index}
              className={`carousel__item ${index === currentIndex ? 'carousel__item--active' : ''} ${textClassName}`}
              style={{
                ...textStyle,
                transition: disableAnimation ? 'none' : 'all 0.4s',
              }}
            >
              {text}
            </Text>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TextCarousel);
